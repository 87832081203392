<template>
  <div class="flex truncate">
    <template v-if="asset">
      <div class="flex items-center" draggable>
        <div v-if="asset.thumbnail" class="flex-shrink-0 w-10 h-10">
          <img class="w-16 h-10 rounded" :src="asset.thumbnail" alt />
        </div>
        <div
          v-else
          class="flex items-center justify-center object-cover rounded shrink-0"
          :class="[sizeClasses, iconColorClass]"
        >
          <fa-icon :icon="itemIcon" :size="iconSize" :class="iconClass" />
        </div>

        <div class="ml-2">
          <div class="text-xs font-medium text-gray-400 truncate">
            {{ asset.name }}
          </div>
          <div v-if="statusBadge" class="text-sm text-gray-500">
            {{ statusBadge }}
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <div
        class="flex items-center justify-center object-cover w-10 h-8 mr-4 bg-gray-500 rounded md:w-12 md:h-10"
      >
        <fa-icon icon="file" size="lg" class="text-white" />
      </div>
      <div class="flex flex-col min-w-0 truncate">
        <div class="flex-1 min-w-0" :class="textSize">
          <h2 class="truncate" />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import mixin from './asset-item-thumb-mixin.js'

export default {
  name: 'AssetItemThumb',

  mixins: [mixin],

  props: {
    asset: {
      required: true
    },
    size: {
      type: String,
      default: 'md'
    }
  },

  computed: {
    textSize() {
      if (this.size === 'xs') return 'text-xs'
      return 'text-sm'
    },

    sizeClasses() {
      if (this.size === 'xs') return 'rounded-sm w-8 h-6 mr-3 items-center'
      return 'w-10 h-8 md:w-12 md:h-10 mr-4'
    },

    showMeta() {
      if (this.dependsOn) return true
      if (this.asset.type === 'folder') return true
      if (this.asset.status !== 'ready') return true
      return false // For now
    },

    dependsOn() {
      return (
        this.asset.depends_on_id !== undefined &&
        this.asset.depends_on_id !== null
      )
    },

    dependsOnName() {
      if (this.asset.depends_on_name === undefined) return ''
      return this.asset.depends_on_name
    },

    metaInfo() {
      if (this.asset.type === 'folder') {
        return this.asset.child_count + ' children'
      }

      return this.asset.meta
    },

    statusBadge() {
      if (this.asset.status === 'review') return 'Needs Review'
      return null
    },

    iconSize() {
      if (this.size === 'xs') {
        if (this.asset.type === 'folder') return 'lg'
        return 'sm'
      }

      if (this.asset.type === 'folder') return '3x'
      return 'lg'
    }
  },

  methods: {
    onSelect(event) {
      this.$emit('select', { asset: this.asset, event: event })
    }
  }
}
</script>
